import React from 'react'
import './style.css'
import UserMenu from '../user-menu'
import { Notifications } from '../../features/notifications'
import { Row } from '@mattilsynet/mt-ui'

const TopMenuElements = () => (
  <Row className="TopMenuElements" align="center">
    <Notifications />
    <UserMenu />
  </Row>
)

export default TopMenuElements
