import React, { Component, ErrorInfo } from 'react'
import Fallback from './Fallback'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

// error boundary can't be a functional component because uses lifecycle methods (componentDidCatch)
class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      hasError: true,
    })
  }

  render() {
    if (this.state.hasError) {
      return <Fallback />
    }

    return this.props.children
  }
}

export default ErrorBoundary
