import React, {
  InputHTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { Button, SearchInput, LoadingDots } from '@mattilsynet/mt-ui'
import './style.css'

type SearchInputProps = React.ComponentProps<typeof SearchInput>

export interface ISearchProps {
  initialSearchValue?: string
  searchLoading?: boolean
  inputValidator?: (
    value: string
  ) => Pick<SearchInputProps, 'status' | 'errorText'> | undefined
  children?: ReactNode
  handleOnSubmit: (value: string) => void
  pattern?: HTMLInputElement['pattern']
  inputMode?: InputHTMLAttributes<HTMLInputElement>['inputMode']
  autoFocus?: boolean
}

export const SearchInputWrapper = ({
  initialSearchValue,
  searchLoading,
  inputValidator,
  children,
  handleOnSubmit,
  pattern,
  inputMode,
  autoFocus = false,
}: ISearchProps) => {
  const [searchValue, setSearchValue] = useState(initialSearchValue || '')
  const [inputErrorText, setInputErrorText] = useState<string | undefined>('')
  const [inputStatus, setInputStatus] =
    useState<SearchInputProps['status']>(undefined)

  const onChangeSeachValue = (e) => setSearchValue(e.currentTarget.value)

  const onRemoveSeachValue = () => setSearchValue('')

  const handleOnClick = () => {
    if (!inputValidator?.(searchValue)) {
      return handleOnSubmit(searchValue)
    }
    setInputStatus(inputValidator?.(searchValue)?.status)
    setInputErrorText(inputValidator?.(searchValue)?.errorText)
  }

  useEffect(() => {
    if (inputErrorText || inputStatus) {
      setInputStatus(inputValidator?.(searchValue)?.status)
      setInputErrorText(inputValidator?.(searchValue)?.errorText)
    }
  }, [inputStatus, inputErrorText, searchValue, inputValidator])

  const submitOnEnter = (e) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur()
      handleOnClick()
    }
  }

  return (
    <div className="search">
      <div className="search-wrapper">
        <SearchInput
          onChange={onChangeSeachValue}
          onKeyDown={submitOnEnter}
          placeholder="Søk"
          value={searchValue}
          fill
          errorText={inputErrorText}
          status={inputStatus}
          inputMode={inputMode || 'search'}
          pattern={pattern}
          onDelete={searchValue ? onRemoveSeachValue : undefined}
          onDeleteAriaLabel="Fjern søk"
          autoFocus={autoFocus}
        />
        <Button secondary onClick={handleOnClick}>
          {searchLoading ? <LoadingDots /> : 'Søk'}
        </Button>
      </div>
      {children}
    </div>
  )
}
