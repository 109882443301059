import * as React from 'react'
import dayjs from 'dayjs'

import { InfoText, TextLink, Text } from '@mattilsynet/mt-ui'
import './styles.css'
import { IVirksomhet } from '../store/types'

export const VirksomhetsDetailsView = ({
  beliggenhetsadresse,
  kontaktperson,
  organisasjonsform,
  organisasjonsnummer,
  overordnetInfo,
  postadresse,
  virksomhetNavn,
  slettedato,
}: IVirksomhet) => {
  return (
    <div id="virksomhet-details">
      <InfoText title="Virksomhetsnavn">{virksomhetNavn}</InfoText>
      <InfoText title="Organisasjonsnummer">
        {organisasjonsnummer?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ') ?? ''}
      </InfoText>
      <InfoText title="Organisasjonsform">{organisasjonsform}</InfoText>

      <div className="link-outside">
        <TextLink
          textColor="#222222"
          linkOutside
          href={`https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=${
            overordnetInfo.organisasjonsnummer || organisasjonsnummer
          }`}
        >
          {overordnetInfo.organisasjonsnummer
            ? 'Vis overordnet enhet'
            : 'Vis enhet'}
        </TextLink>
      </div>

      {(beliggenhetsadresse || postadresse) && (
        <h3 className="title">Adresser</h3>
      )}
      {beliggenhetsadresse && (
        <InfoText title="Beliggenhetsadresse">
          {beliggenhetsadresse.adresse?.map((adr) => (
            <div key={adr}> {adr} </div>
          ))}
          <div>
            {beliggenhetsadresse.postnummer} {beliggenhetsadresse.poststed}
          </div>
        </InfoText>
      )}

      {postadresse && (
        <InfoText title="Postadresse">
          {postadresse.adresse?.map((adr) => <div key={adr}> {adr} </div>)}
          <div>
            {postadresse.postnummer} {postadresse.poststed}
          </div>
        </InfoText>
      )}

      {!slettedato && (
        <>
          <h3 className="title">Kontaktpersoner</h3>
          <InfoText title={kontaktperson.rolle}>
            <div className="name">{kontaktperson.navn}</div>
            {overordnetInfo.epostadresse && (
              <TextLink
                textColor="#222222"
                href={`mailto:${overordnetInfo.epostadresse}`}
              >
                {overordnetInfo.epostadresse}
              </TextLink>
            )}
            {overordnetInfo.telefonnummer && (
              <TextLink
                textColor="#222222"
                href={`tel:${overordnetInfo.telefonnummer}`}
              >
                {overordnetInfo.telefonnummer}
              </TextLink>
            )}
            {overordnetInfo.mobiltelefonnummer && (
              <TextLink
                textColor="#222222"
                href={`tel:${overordnetInfo.mobiltelefonnummer}`}
              >
                {overordnetInfo.mobiltelefonnummer}
              </TextLink>
            )}
          </InfoText>
        </>
      )}

      {slettedato && (
        <Text size="normal">
          Virksomheten ble slettet fra Brønnøysundregistrene{' '}
          {dayjs(slettedato).format('D. MMM YYYY')}.
        </Text>
      )}
    </div>
  )
}
