import React, { useContext } from 'react'
import {
  Button,
  Column,
  Row,
  ThemeContext,
  Text,
  LoadingSpinner,
  ErrorBox,
} from '@mattilsynet/mt-ui'
import { IRemoteKodeverk } from '../../features/kodeverk/store/types'

import './style.css'
import { ModalWrapper } from '../../components/modal-wrapper'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { ModalButton } from '../../components/modal-button'
import { modalContentPadding } from '../../components/modal-buttons-container/constants'

const IFELT = 'IFELT'

interface IHjemmelModalProps {
  isOpen: boolean
  onCancel: () => void
  hjemmelList?: IRemoteKodeverk[]
  onSelectHjemmel: (selectedHjemmel: IRemoteKodeverk) => void
  selectedHjemmel?: string
  loading: boolean
  error: boolean
  onRetry: () => void
}
export const HjemmelModal = ({
  isOpen,
  onCancel,
  hjemmelList,
  onSelectHjemmel,
  selectedHjemmel,
  loading,
  error,
  onRetry,
}: IHjemmelModalProps) => {
  const theme = useContext(ThemeContext)

  const renderModalContent = () => {
    if (loading) {
      return (
        <Row center>
          <LoadingSpinner title="Henter innhold..." small />
        </Row>
      )
    }
    if (error) {
      return (
        <Row center>
          <ErrorBox
            errorText="Kunne ikke hente innhold."
            errorActionText="Prøv igjen..."
            errorAction={() => onRetry()}
          />
        </Row>
      )
    }

    return (
      <Column padding={modalContentPadding}>
        {hjemmelList
          ?.filter((hjemmelItem: IRemoteKodeverk) =>
            hjemmelItem.filter?.split('|').includes(IFELT)
          )
          .map((hjemmelItem: IRemoteKodeverk, index: number) => {
            const isSelectedHjemmel = hjemmelItem.codeString === selectedHjemmel
            return (
              <Row
                key={hjemmelItem.codeString}
                backgroundColor={index % 2 ? theme.white : theme.gray7}
                padding={2}
                justify="space-between"
                align="center"
              >
                <Column spacing={1} fluid>
                  <Text size="normal">{hjemmelItem?.displayNames?.no}</Text>
                  <Text size="medium" weight="light">
                    {hjemmelItem?.displayNames?.descr_ifelt}
                  </Text>
                </Column>
                <Button
                  width="8em"
                  secondary={!isSelectedHjemmel}
                  onClick={() => {
                    onSelectHjemmel(hjemmelItem)
                    onCancel()
                  }}
                  small
                >
                  {isSelectedHjemmel ? 'Valgt' : 'Velg'}
                </Button>
              </Row>
            )
          })}
      </Column>
    )
  }

  return (
    <>
      <ModalWrapper
        fullscreenMobile
        isOpen={isOpen}
        onCancel={onCancel}
        title="Velg Hjemmel"
        className="hjemmel-modal"
      >
        {renderModalContent()}
        <ModalButtonsContainer>
          <ModalButton secondary onClick={onCancel}>
            Lukk
          </ModalButton>
        </ModalButtonsContainer>
      </ModalWrapper>
    </>
  )
}
