import React, { useCallback } from 'react'
import { Column, Row, Text } from '@mattilsynet/mt-ui'
import { kodeverk } from '../../features/kodeverk'
import dayjs from 'dayjs'
import { InformationBox } from '../information-box'
import { CopyToClipboardButton } from '../copy-to-clipboard'
import { copySaknummer, useSingleToast } from '../../common/toast'
import { IKvitteringData } from '../../ducks/kvittering/types'

interface ISammenstillingInfoProps {
  kvittering: IKvitteringData
}

export const SammenstillingInfo = ({
  kvittering,
}: ISammenstillingInfoProps) => {
  const singleToast = useSingleToast()

  const {
    hjemmelForUnntattOffentlighet,
    noarksakAar,
    noarksakSekvensnummer,
    tilsynsdato,
    tilsynsdatoSlutt,
  } = kvittering

  const onCopySaknummer = useCallback(
    () => singleToast.showToast(copySaknummer()),
    [singleToast]
  )

  const hjemmelObject = kodeverk.hooks
    .useKodeverk('TILGANGSHJEMMEL')
    .data?.find((item) => item.codeString === hjemmelForUnntattOffentlighet)

  const dato = dayjs(tilsynsdato).format('DD. MMM YYYY')
  const datoSlutt =
    tilsynsdatoSlutt && dayjs(tilsynsdatoSlutt).isAfter(tilsynsdato)
      ? dayjs(tilsynsdatoSlutt).format('DD. MMM YYYY')
      : undefined
  const saksnummer = `${noarksakAar}/${noarksakSekvensnummer}`

  return (
    <InformationBox>
      <InformationBox.Header>
        Dato for tilsyn: {dato} {datoSlutt && `- ${datoSlutt}`}. Saksnummer:{' '}
        {saksnummer}
        &nbsp;
        <CopyToClipboardButton onCopied={onCopySaknummer} value={saksnummer} />
      </InformationBox.Header>

      {hjemmelForUnntattOffentlighet && (
        <Row padding={[1, 0, 0, 0]}>
          <Column>
            <Row>
              <Text weight="bold">
                Tilsynskvitteringen er unntatt offentlighet
              </Text>
            </Row>
            {hjemmelObject !== undefined && (
              <Row>
                <Column>
                  <Row>
                    <Text>{hjemmelObject.displayNames?.no}</Text>
                  </Row>
                  <Row>
                    <Text>{hjemmelObject.displayNames?.descr_ifelt}</Text>
                  </Row>
                </Column>
              </Row>
            )}
          </Column>
        </Row>
      )}
    </InformationBox>
  )
}
