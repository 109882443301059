import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { orgenhetActions, orgenhetSelectors } from '../../ducks/orgenhet'
import { LoadingDots } from '@mattilsynet/mt-ui'
import { useTypedSelector } from '../../../../common/custom-hooks'

const OrgenhetName: React.FC<{ mtEnhet: string | undefined }> = ({
  mtEnhet = '',
}) => {
  const orgenhet = useTypedSelector(orgenhetSelectors.dataByMTEnhet(mtEnhet))
  const dispatch = useDispatch()
  const fetchOrgenhet = useCallback(
    (mtEnhet) => dispatch(orgenhetActions.fetchId(mtEnhet)),
    [dispatch]
  )

  const [shouldFetch, setShouldFetch] = useState(true)

  useEffect(() => {
    if (mtEnhet && (!orgenhet || (orgenhet.error && shouldFetch))) {
      fetchOrgenhet(mtEnhet)
      setShouldFetch(false)
    }
  }, [fetchOrgenhet, mtEnhet, orgenhet, shouldFetch])

  if (!mtEnhet) {
    return <>-</>
  } else if (!orgenhet || orgenhet.loading) {
    return <LoadingDots />
  } else if (orgenhet.error) {
    return <>Kunne ikke hente mtEnhet..</>
  } else return <>{orgenhet.data?.name}</>
}

export default OrgenhetName
