import React, { ChangeEvent, useState } from 'react'
import { Column, Row, Text, TextArea } from '@mattilsynet/mt-ui'
import { useDebounceFn } from '../../common/custom-hooks'
import { LabelWithInfo } from '../label-with-info'
import { useKvitteringId } from '../../common/kvittering-context'
import { useKvittering, useUpdateKvittering } from '../../features/kvitteringer'
import { DEBOUNCE_MS } from '../../constants'

const GenerellInfomasjonCard = () => {
  const kvitteringId = useKvitteringId()

  const { data: kvittering } = useKvittering(kvitteringId)

  const { mutate: updateKvittering } = useUpdateKvittering()

  const debounce = useDebounceFn(DEBOUNCE_MS)

  const [generellInformasjon, setGenerellInformasjon] = useState(
    kvittering?.generellInformasjon
  )

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setGenerellInformasjon(e.target.value)

    if (kvittering) {
      debounce(() =>
        updateKvittering({
          kvittering: { ...kvittering, generellInformasjon: e.target.value },
        })
      )
    }
  }

  return (
    <Row id="generell-informasjon">
      <Column fluid spacing={1}>
        <LabelWithInfo label="Faktabeskrivelse om tilsynsobjektet/ene">
          <div>
            <Text>
              Feltet kan benyttes til å registrere nødvendige faktaopplysninger
              om tilsynsobjektet/ene som ikke passer å skrive i
              observasjonsfeltene. F. eks. ved endring i produksjon og endring i
              antall dyr.
            </Text>
            <br />
            <Text>OBS: Feltet skal ikke inneholde vurderinger.</Text>
            <Text>Skriv kort og konsist.</Text>
          </div>
        </LabelWithInfo>
        <TextArea
          onChange={onChange}
          placeholder="F. eks. antall dyr"
          value={generellInformasjon ?? ''}
          defaultRows={2}
          maxLength={500}
          infoText={`${generellInformasjon?.length ?? 0}/500`}
        />
      </Column>
    </Row>
  )
}

export default GenerellInfomasjonCard
