import { combineEpics, Epic } from 'redux-observable'
import {
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators'
import { EMPTY, merge, of } from 'rxjs'
import { kassasjonerActions, kassasjonerActionTypes } from '../store'
import { IKassasjonerActions } from '../store/types'
import { ICommonApi } from '../../../../../api'
import { IStoreState } from '../../../../../reducers/types'
import { ENDPOINT } from '../../../../../constants'
import { commonRedux } from '../../../../../common/redux'
import { createQueryStringFromObject } from '../../../../../common/createQueryStringFromObject'
const { isOfType } = commonRedux

export const fetchKassasjonerEpic =
  (
    commonApi: ICommonApi
  ): Epic<IKassasjonerActions, IKassasjonerActions, IStoreState> =>
  (action$, state$) =>
    action$.pipe(
      filter(isOfType(kassasjonerActionTypes.FETCH_LIST)),
      withLatestFrom(state$),
      mergeMap(([{ funnIds, kassasjonIds }, state]) => {
        const idsToFetch = funnIds || kassasjonIds || []

        if (idsToFetch.length === 0) return EMPTY
        const query = funnIds
          ? { funnIds: idsToFetch }
          : { kassasjonIds: idsToFetch }
        return of(state).pipe(
          commonApi.get(
            `${
              ENDPOINT.KASSASJONER
            }/v1/kassasjoner${createQueryStringFromObject('?')({
              ...query,
              size: 2000,
            })}`,
            state
          ),
          map((resp: any) => {
            return resp._embedded?.kassasjonList || []
          }),
          mergeMap((kassasjoner: IKassasjonRemote.Kassasjon[]) => {
            return merge(of(kassasjonerActions.fetchListOk(kassasjoner)))
          }),
          catchError((err) =>
            of(kassasjonerActions.fetchListFailed(err.message))
          )
        )
      })
    )

const kassasjonerEpics = (commonApi: ICommonApi) =>
  combineEpics(fetchKassasjonerEpic(commonApi))

export default kassasjonerEpics
