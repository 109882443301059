import React from 'react'
import {
  ErrorBox,
  LoadingSpinner,
  Table,
  TableCell,
  TableRow,
  Text,
} from '@mattilsynet/mt-ui'
import { useFaktabeskrivelserTidligereTilsyn } from './hooks'
import dayjs from 'dayjs'

interface IFaktabeskrivelserFraTidligereTilsynProps {
  tilsynsobjektId: string
}

export const FaktabeskrivelserFraTidligereTilsyn = ({
  tilsynsobjektId,
}: IFaktabeskrivelserFraTidligereTilsynProps) => {
  const { faktabeskrivelser, error, fetchFaktabeskrivelserTidligereTilsyn } =
    useFaktabeskrivelserTidligereTilsyn(tilsynsobjektId)

  const headers = ['Tilsynsdato', 'Beskrivelse'].map((element) => ({
    display: element,
  }))

  if (error) {
    return (
      <ErrorBox
        errorActionText="Last innhold på nytt"
        errorAction={fetchFaktabeskrivelserTidligereTilsyn}
        errorText="Kunne ikke hente faktabeskrivelser fra tidligere tilsyn"
      />
    )
  }

  if (!faktabeskrivelser) {
    return <LoadingSpinner delay={0} title="Henter faktabeskrivelser" />
  }

  if (faktabeskrivelser.length === 0) {
    return (
      <Text>
        Fant ingen faktabeskrivelser fra tidligere tilsyn for dette
        tilsynsobjektet.
      </Text>
    )
  }

  const sortedFaktabeskrivelser = faktabeskrivelser
    .slice()
    .sort((a, b) => Date.parse(b.opprettet) - Date.parse(a.opprettet))

  return (
    <Table header={headers}>
      {sortedFaktabeskrivelser.map(
        ({ adhocbeskrivelseId, opprettet, beskrivelse }) => (
          <TableRow key={adhocbeskrivelseId}>
            <TableCell>
              <Text>{dayjs(opprettet).format('DD.MM.YYYY')}</Text>
            </TableCell>
            <TableCell>
              <Text whiteSpace="pre-wrap">{beskrivelse}</Text>
            </TableCell>
          </TableRow>
        )
      )}
    </Table>
  )
}
