import React from 'react'
import ToastArea from '../toast-area'
import './style.css'

export interface IFixedToastAreaProps {
  id?: string
}

const FixedToastArea = ({ id }: IFixedToastAreaProps) => (
  <div className="common__FixedToastArea" id={id ?? 'common__FixedToastArea'}>
    <ToastArea />
  </div>
)

export default FixedToastArea
