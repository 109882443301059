import React, { useCallback, useEffect } from 'react'
import {
  restriksjonerActions,
  restriksjonerSelectors,
} from '../../ducks/restriksjoner/store'
import { useDispatch } from 'react-redux'
import {
  Column,
  LoadingSpinner,
  ErrorBox,
  Table,
  TableCell,
  TableRow,
} from '@mattilsynet/mt-ui'
import dayjs from 'dayjs'
import './style.css'
import { useTypedSelector } from '../../../../common/custom-hooks'

export const useRestriksjonerHook = (tilsynsobjektId: string) => {
  const restriksjonerData = useTypedSelector(
    restriksjonerSelectors.getById(tilsynsobjektId)
  )

  const dispatch = useDispatch()
  const fetchRestriksjoner = useCallback(() => {
    dispatch(restriksjonerActions.fetchListByIds([tilsynsobjektId]))
  }, [dispatch, tilsynsobjektId])

  useEffect(() => {
    if (
      !restriksjonerData ||
      (!restriksjonerData.data &&
        !restriksjonerData.loading &&
        !restriksjonerData.error)
    ) {
      fetchRestriksjoner()
    }
  }, [tilsynsobjektId, fetchRestriksjoner, restriksjonerData])
  return { restriksjonerData, fetchRestriksjoner }
}

interface IRestriksjonerProps {
  tilsynsobjektId: string
}

export const Restriksjoner = ({ tilsynsobjektId }: IRestriksjonerProps) => {
  const { fetchRestriksjoner, restriksjonerData } =
    useRestriksjonerHook(tilsynsobjektId)

  if (!restriksjonerData || restriksjonerData.loading) {
    return <LoadingSpinner title="Laster restriksjoner.." />
  }

  if (restriksjonerData.error || !restriksjonerData.data) {
    return (
      <Column margin={[0, 2]}>
        <ErrorBox
          errorAction={fetchRestriksjoner}
          errorActionText="Last innhold på nytt"
          errorText="Kunne ikke hente restriksjoner"
        />
      </Column>
    )
  }

  if (!restriksjonerData.data.restriksjoner.length) {
    return (
      <Column margin={[0, 2]}>
        <ErrorBox errorText="Hos denne produsenten har det ikke tidligere vært ilagt restriksjoner." />
      </Column>
    )
  }

  const restriksjonListByDate = restriksjonerData.data.restriksjoner.sort(
    (a, b) => {
      if (a.tilDato === b.tilDato) return 0
      if (!(a.tilDato && b.tilDato)) return a.tilDato ? 1 : -1
      return dayjs(a.tilDato).isBefore(b.tilDato) ? 1 : -1
    }
  )

  return (
    <Table
      className="restriksjoner-table"
      header={[
        { display: 'Restriksjonsårsak' },
        { display: 'Gjelder fra' },
        { display: 'Gjelder til og med' },
        { display: 'Sykdomstilfelle' },
      ]}
    >
      {restriksjonListByDate.map((restriksjon) => (
        <TableRow key={`${restriksjon.aarsak?.id}${restriksjon.fraDato}`}>
          <TableCell>{restriksjon.aarsak?.beskrivelse}</TableCell>
          <TableCell>
            {restriksjon.fraDato
              ? dayjs(restriksjon.fraDato).format('DD.MM.YY')
              : '-'}
          </TableCell>
          <TableCell>
            {restriksjon.tilDato
              ? dayjs(restriksjon.tilDato).format('DD.MM.YY')
              : '-'}
          </TableCell>
          <TableCell>
            <Column spacing={1}>
              {restriksjon?.diagnose?.beskrivelse && (
                <p>
                  <span className="label-title">Diagnose:</span>
                  <span>{` ${restriksjon?.diagnose?.beskrivelse}`}</span>
                </p>
              )}
              {restriksjon?.mistenktSykdom?.beskrivelse && (
                <p>
                  <span className="label-title">Mistanke om:</span>
                  <span>{` ${restriksjon?.mistenktSykdom?.beskrivelse}`}</span>
                </p>
              )}
            </Column>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
