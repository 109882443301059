import React, { useCallback, useEffect, useState } from 'react'
import {
  Grid,
  GridItem,
  Column,
  Row,
  Button,
  InfoText,
  Header,
  ErrorBox,
  TextLink,
  RowItem,
} from '@mattilsynet/mt-ui'
import { useTypedSelector } from '../../common/custom-hooks'
import {
  offlineOnHentFraKollega,
  offlineOnOpenFordelTilKollega,
  useSingleToast,
} from '../../common/toast'
import { kvitteringSelectors } from '../../ducks/kvittering/selectors'
import { ADRESSE_ERROR, IKvitteringData } from '../../ducks/kvittering/types'
import { DeleteModal } from '../../modals'
import { KvitteringerList } from '../../components/kvitteringer-list'
import { PageLoading } from '../../components/page-loading'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { FordelTilModal } from '../../modals/fordel-til-kollega-modal'
import { IStoreState } from '../../reducers/types'
import { HentFraKollegaModal } from '../../modals/hent-fra-kollega-modal'
import { BilderUploadDangerCard } from '../../components/bilder-upload-danger-card'
import { queryClient } from '../../api/query-client'
import { deltakerQueryHelpers } from '../../features/deltakere/queries/helpers'

interface IHomeRouteViewProps {
  onForberedNyttTilsyn: () => void
  onClickTilsyn: (kvittering: IKvitteringData) => void
  onDeleteKvittering: (kvittering: IKvitteringData) => void
  onRedigerTilsyn: (kvittering: IKvitteringData) => void
}

export const HomeRouteView = ({
  onForberedNyttTilsyn,
  onClickTilsyn,
  onDeleteKvittering,
  onRedigerTilsyn,
}: IHomeRouteViewProps) => {
  const [isDeleteKvittteringModalOpen, setIsDeleteKvittteringModalOpen] =
    useState(false)
  const [isFordelKvittteringModalOpen, setIsFordelKvittteringModalOpen] =
    useState(false)
  const [isHentFraKollegaModalOpen, setIsHentFraKollegaModalOpen] =
    useState(false)
  const initialSelectedKvitteringState = {} as IKvitteringData
  const [selectedKvittering, setSelectedKvittering] = useState(
    initialSelectedKvitteringState
  )

  const dispatch = useDispatch()

  const singleToast = useSingleToast()

  const kvitteringer = useTypedSelector(
    (state) => state.kvittering.kvitteringer
  )
  const isLoading = useTypedSelector((state) => state.kvittering.loading)
  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)
  const error = useTypedSelector((state) => state.kvittering.error)

  const paaBegyntKvitteringer = useTypedSelector(
    kvitteringSelectors.getPaabegynt
  )

  const forberedtKvitteringer = useTypedSelector(
    kvitteringSelectors.getForberedt
  )

  const ferdigstiltKvitteringer = useTypedSelector(
    kvitteringSelectors.getFerdigstilt
  )

  useEffect(() => {
    const kvitteringIds = ferdigstiltKvitteringer.map(
      (kvittering) => kvittering.id
    )

    kvitteringIds.forEach((kvitteringId) => {
      if (!kvitteringId) return

      // Removing tanstack caches for current kvittering
      queryClient.removeQueries({ queryKey: [{ kvitteringId }] })
      // This can be removed when key is updated to include kvitteringId
      deltakerQueryHelpers.removeDeltakerQueries(kvitteringId)
    })
  }, [ferdigstiltKvitteringer])

  const arkivertKvitteringer = useTypedSelector(kvitteringSelectors.getArkivert)

  const feiletKvitteringer = useTypedSelector(kvitteringSelectors.getFeilet)

  const onCancelModal = () => {
    setIsDeleteKvittteringModalOpen(false)
    setSelectedKvittering(initialSelectedKvitteringState)
  }

  const onOpenDeleteModal = (kvittering: IKvitteringData) => {
    setIsDeleteKvittteringModalOpen(true)
    setSelectedKvittering(kvittering)
  }

  const onOpenFordelTilKollegaModal = (kvittering: IKvitteringData) => {
    if (isOffline) {
      return singleToast.showToast(offlineOnOpenFordelTilKollega())
    }
    setIsFordelKvittteringModalOpen(true)
    setSelectedKvittering(kvittering)
  }

  const onHentFraKollega = useCallback(() => {
    if (isOffline) {
      return singleToast.showToast(offlineOnHentFraKollega())
    }
    setIsHentFraKollegaModalOpen(true)
  }, [isOffline, singleToast])

  const withMargin = useMediaQuery({ query: '(min-width: 1152px)' })

  const withPadding = useMediaQuery({ query: '(min-width: 500px)' })

  const renderKvitteringer = () => {
    if (error && error !== ADRESSE_ERROR) {
      return (
        <Row center>
          <ErrorBox
            errorText="Kunne ikke hente innhold."
            errorActionText="Prøv igjen..."
            errorAction={() => dispatch(kvitteringActions.fetchKvitteringer())}
          />
        </Row>
      )
    }

    if (kvitteringer.length === 0) {
      return (
        <Row justify="center">
          <InfoText>
            <p>Du har ingen påbegynte tilsynskvitteringer</p>
          </InfoText>
        </Row>
      )
    }

    return (
      <Row>
        <Column spacing={2} fluid>
          <KvitteringerList
            title="Tilsynskvitteringer med feil"
            kvitteringer={feiletKvitteringer}
            onClickTilsyn={() => undefined}
            onOpenDeleteModal={onOpenDeleteModal}
            onRedigerTilsyn={onRedigerTilsyn}
            onFordelTilKollega={onOpenFordelTilKollegaModal}
            buttonText="Feilet"
          />

          <KvitteringerList
            title="Tilsynskvitteringer i arkivet"
            kvitteringer={arkivertKvitteringer}
            onClickTilsyn={onClickTilsyn}
            onOpenDeleteModal={onOpenDeleteModal}
            buttonText="Ferdigstill og send ut"
            onRedigerTilsyn={onRedigerTilsyn}
            onFordelTilKollega={onOpenFordelTilKollegaModal}
          />
          <KvitteringerList
            title="Påbegynte tilsyn"
            kvitteringer={paaBegyntKvitteringer}
            onClickTilsyn={onClickTilsyn}
            onOpenDeleteModal={onOpenDeleteModal}
            buttonText="Fortsett tilsyn"
            onRedigerTilsyn={onRedigerTilsyn}
            onFordelTilKollega={onOpenFordelTilKollegaModal}
          />
          <KvitteringerList
            title="Forberedte tilsyn"
            kvitteringer={forberedtKvitteringer}
            onClickTilsyn={onClickTilsyn}
            buttonText="Start tilsyn"
            onOpenDeleteModal={onOpenDeleteModal}
            onRedigerTilsyn={onRedigerTilsyn}
            onFordelTilKollega={onOpenFordelTilKollegaModal}
          />
          <KvitteringerList
            title="Ferdigstilte kvitteringer"
            info="Viser tilsynskvitteringer som er ferdigstilt siste 7 dager."
            kvitteringer={ferdigstiltKvitteringer}
            onClickTilsyn={() => undefined}
            onOpenDeleteModal={onOpenDeleteModal}
            onRedigerTilsyn={onRedigerTilsyn}
            onFordelTilKollega={onOpenFordelTilKollegaModal}
          />
        </Column>
      </Row>
    )
  }

  if (isLoading && kvitteringer.length === 0) {
    return <PageLoading loadingText="Laster tilsynskvittering..." />
  }

  return (
    <Grid>
      <DeleteModal
        isOpen={isDeleteKvittteringModalOpen}
        onCancel={onCancelModal}
        onDelete={() => {
          onDeleteKvittering(selectedKvittering)
          onCancelModal()
        }}
        title="Er du sikker på at du vil slette tilsynet?"
        confirmText="Ja, jeg vil slette tilsynet"
        cancelText="Avbryt"
      />
      {isFordelKvittteringModalOpen && (
        <FordelTilModal
          isOpen={isFordelKvittteringModalOpen}
          onCancel={() => setIsFordelKvittteringModalOpen(false)}
          kvittering={selectedKvittering}
        />
      )}
      {isHentFraKollegaModalOpen && (
        <HentFraKollegaModal
          isOpen={isHentFraKollegaModalOpen}
          onCancel={() => setIsHentFraKollegaModalOpen(false)}
        />
      )}

      {feiletKvitteringer.length > 0 && (
        <GridItem xl={[3, -3]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
          {!isLoading &&
            feiletKvitteringer.length > 0 &&
            feiletKvitteringer.map((kvittering) => {
              if (
                !kvittering.noarksakAar ||
                !kvittering.noarksakSekvensnummer
              ) {
                return null
              }
              const sakNumber = `${kvittering.noarksakAar}/${kvittering.noarksakSekvensnummer}`

              return (
                <Row key={kvittering.id}>
                  <RowItem flex={1}>
                    <BilderUploadDangerCard sakNumber={sakNumber} />
                  </RowItem>
                </Row>
              )
            })}
        </GridItem>
      )}

      <GridItem xl={[3, -3]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}>
        {/* <Grid id="home"> */}
        {/* <GridItem xl={[2, -2]} lg={[1, -1]} md={[1, -1]} sm={[1, -1]}> */}
        <Column
          spacing={5}
          padding={[2, withPadding ? 1 : 0]}
          margin={[0, withMargin ? 15 : 0]}
        >
          <Row justify="center">
            <Header as="h1" size="applicationHeader">
              Tilsynskvittering
            </Header>
          </Row>
          <Row justify="center" padding={[0, 2]}>
            <Column fluid>
              <Row>
                <Button large onClick={onForberedNyttTilsyn} fill>
                  Forbered nytt tilsyn
                </Button>
              </Row>

              <Row margin={[2, 0, 0, 0]} justify="center">
                <TextLink onClick={onHentFraKollega}>Hent fra kollega</TextLink>
              </Row>
            </Column>
          </Row>
          {renderKvitteringer()}
        </Column>
        {/* </GridItem> */}
        {/* </Grid> */}
      </GridItem>
    </Grid>
  )
}
