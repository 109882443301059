import { Column, Row, TextLink } from '@mattilsynet/mt-ui'
import React, { useCallback, useEffect } from 'react'
import { IStoreState } from '../../reducers/types'
import { useTypedSelector } from '../../common/custom-hooks'
import './style.css'
import { useBilde, useBildeMetadata } from '../../features/bilder'
import { useKvitteringId } from '../../common/kvittering-context'

interface IFullImagePageProps {
  onTilbake: () => void
}

const FullImagePage = ({ onTilbake }: IFullImagePageProps) => {
  const kvitteringId = useKvitteringId()
  const imageId = useTypedSelector(
    (state: IStoreState) => state.ui.openImageNavigationModal.imageId
  )
  useEffect(() => {
    if (imageId === undefined) {
      onTilbake()
    }
  }, [imageId, onTilbake])

  const [bildeMetadata] = useBildeMetadata(kvitteringId, imageId ?? '')
  const [url] = useBilde(kvitteringId, bildeMetadata?.id ?? '', 'original')

  const onBack = useCallback(() => {
    onTilbake()
  }, [onTilbake])

  return (
    <Column className="fullSizeImage">
      <Row padding={[0, 0, 2, 0]}>
        <TextLink onClick={onBack} leftArrow>
          Tilbake
        </TextLink>
      </Row>
      <Row>
        <img src={url} alt={bildeMetadata?.description} />
      </Row>
    </Column>
  )
}

export { FullImagePage }
