import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import {
  Column,
  TextLink,
  Text,
  NotificationMenuitem,
  NotificationMenuWrapper,
  RoundIndicator,
} from '@mattilsynet/mt-ui'

import { actions, selectors } from '../store'
import { ILink, INotification } from '../store/types'
import { INotificationsProps } from './index'
import { copyToClipboard } from '../../../common/helpers'

const NotificationArea = ({
  customActions,
}: Pick<INotificationsProps, 'customActions'>) => {
  const dispatch = useDispatch()
  const [openIds, setOpenIds] = useState<string[]>([])
  const notifications = useSelector(selectors.getSorted)

  const onDelete = (notification) => dispatch(actions.delete(notification))
  const onDeleteAll = (notifications) =>
    notifications.map((notification) => onDelete(notification))

  const readNotification = (notification) =>
    dispatch(actions.read(notification))

  const toggleOpen = (notification) => {
    if (openIds.includes(notification.id)) {
      const newOpenIds = openIds.filter(
        (currentId) => currentId !== notification.id
      )
      return setOpenIds(newOpenIds)
    } else {
      readNotification(notification)
      return setOpenIds([...openIds, notification.id])
    }
  }

  const getOnclickFunc = (action: ILink['link']['action']) => {
    if (action?.type === 'copyToClipboard') {
      return () => copyToClipboard((action?.payload as string) || '')
    }
  }

  const renderWithMoreInfo = (
    customActions,
    { withMoreInfo }: INotification
  ) => {
    if (!withMoreInfo) return null

    if ('link' in withMoreInfo) {
      return (
        <Column spacing={0.5}>
          <TextLink
            linkOutside
            onClick={async () => {
              if (customActions?.(withMoreInfo?.link?.action)) {
                customActions?.(withMoreInfo?.link?.action)()
              } else {
                await getOnclickFunc(withMoreInfo?.link?.action)?.()
              }
              window.open(withMoreInfo?.link?.url)
            }}
          >
            {withMoreInfo?.link?.text}
          </TextLink>
          <Text size="small">{withMoreInfo?.link?.helperText}</Text>
        </Column>
      )
    }

    if ('text' in withMoreInfo) {
      return <Text size="medium">{withMoreInfo?.text}</Text>
    }
  }

  const renderWithLink = ({ link }: INotification) => {
    if (!link) return null

    return (
      <Column spacing={0.5}>
        <TextLink onClick={link.onClick}>{link.text}</TextLink>
      </Column>
    )
  }

  return (
    <NotificationMenuWrapper onDeleteAll={() => onDeleteAll(notifications)}>
      {notifications.map((notification) => (
        <NotificationMenuitem
          key={notification.id}
          title={notification.title}
          scope={notification.scope}
          body={notification.body}
          footer={`Melding mottatt: ${dayjs(
            notification.publishedTime || notification.created
          ).format('D. MMM YYYY, H.mm')}.`}
          onDelete={() => onDelete(notification)}
          onToggle={() => toggleOpen(notification)}
          isOpen={openIds.includes(notification.id!)}
          seen={!!notification.seen}
          status={notification.status}
          titleIcon={
            notification.scope ? <RoundIndicator>i</RoundIndicator> : undefined
          }
        >
          {renderWithMoreInfo(customActions, notification)}
          {renderWithLink(notification)}
        </NotificationMenuitem>
      ))}
    </NotificationMenuWrapper>
  )
}

export default NotificationArea
