import React from 'react'
import { Accordion, Column, InfoText, ShadowBox } from '@mattilsynet/mt-ui'
import TilsynsobjektCard from './tilsynsobjekt-card-view'
import { useTilsynsobjektHooks } from '../ducks/tilsynsobjekt'
import { useToggleCards } from '../../../common/custom-hooks'
import { TidligereTilsynskvitteringer } from './tidligere-tilsynskvitteringer'
import { FaktabeskrivelserFraTidligereTilsyn } from './faktabeskrivelser-tidligere-tilsyn'
import { ProdusentFunnHistorikk } from './funn-historikk'
import { Proveanalysesvar } from './proveanalysesvar'
import { Restriksjoner } from './restriksjoner'
import { ProdusentMeldingsHistorikk } from './produsent-meldings-historikk'
import Godkjenninger from './godkjenninger'
import OrgenhetName from './orgenhet-name'
import { Brreg } from '../../brreg'
import './styles.css'

export const Tilsynsobjektinfo = ({
  tilsynsobjektId,
  visibleCards,
}: {
  tilsynsobjektId: string
  visibleCards?: string[]
}) => {
  const { tilsynsobjekt, tilsynsobjektLoadingStatus, fetchTilsynsobjekt } =
    useTilsynsobjektHooks(tilsynsobjektId)
  const initialOpenCards =
    new URLSearchParams(location.search).get('initialOpenCards')?.split(',') ||
    []

  const { openCards, toggleCard } = useToggleCards(initialOpenCards)

  return (
    <div id="tilsynsobjekt-details">
      <div className="page-content">
        <main>
          {
            <section className="top-content">
              <ShadowBox>
                <TilsynsobjektCard
                  tilsynsobjekt={tilsynsobjekt}
                  fetchTilsynsobjekt={fetchTilsynsobjekt}
                  loadingStatus={tilsynsobjektLoadingStatus}
                />
              </ShadowBox>
            </section>
          }
          {!!tilsynsobjekt && (
            <>
              <section>
                {(!visibleCards ||
                  visibleCards.includes('tilsynskvittering')) && (
                  <ShadowBox>
                    <Accordion
                      className="main-section-accordion"
                      title="Tidligere tilsynskvitteringer"
                      onClick={toggleCard('tilsynskvittering')}
                      open={openCards.includes('tilsynskvittering')}
                    >
                      <TidligereTilsynskvitteringer
                        tilsynsobjektId={tilsynsobjektId}
                      />
                    </Accordion>
                  </ShadowBox>
                )}
                {(!visibleCards ||
                  visibleCards.includes(
                    'faktabeskrivelser-tidligere-tilsyn'
                  )) && (
                  <ShadowBox>
                    <Accordion
                      onClick={toggleCard('faktabeskrivelser-tidligere-tilsyn')}
                      open={openCards.includes(
                        'faktabeskrivelser-tidligere-tilsyn'
                      )}
                      title="Faktabeskrivelser registrert på tilsyn"
                    >
                      <FaktabeskrivelserFraTidligereTilsyn
                        tilsynsobjektId={tilsynsobjektId}
                      />
                    </Accordion>
                  </ShadowBox>
                )}
                {(!visibleCards ||
                  visibleCards.includes('am-pm-historikk')) && (
                  <ShadowBox>
                    <Accordion
                      className="main-section-accordion"
                      title="AM- og PM-historikk"
                      onClick={toggleCard('am-pm-historikk')}
                      open={openCards.includes('am-pm-historikk')}
                      paddingChildren={[1.5, 2.5, 1.5, 2.5]}
                    >
                      <ProdusentFunnHistorikk
                        tilsynsobjektId={tilsynsobjektId}
                      />
                    </Accordion>
                  </ShadowBox>
                )}
                {(!visibleCards ||
                  visibleCards.includes('proveanalysesvar')) && (
                  <ShadowBox>
                    <Accordion
                      className="main-section-accordion"
                      title="Prøver og prøveanalysesvar"
                      onClick={toggleCard('proveanalysesvar')}
                      open={openCards.includes('proveanalysesvar')}
                      paddingChildren={[1, 0, 1, 0]}
                    >
                      <Proveanalysesvar
                        produsentTilsynsobjektId={tilsynsobjektId}
                      />
                    </Accordion>
                  </ShadowBox>
                )}
                {(!visibleCards || visibleCards.includes('restriksjoner')) && (
                  <ShadowBox>
                    <Accordion
                      className="main-section-accordion"
                      title="Restriksjoner"
                      onClick={toggleCard('restriksjoner')}
                      open={openCards.includes('restriksjoner')}
                      paddingChildren={[1, 0, 1, 0]}
                    >
                      <Restriksjoner tilsynsobjektId={tilsynsobjektId} />
                    </Accordion>
                  </ShadowBox>
                )}
                {(!visibleCards || visibleCards.includes('meldinger')) && (
                  <ShadowBox>
                    <Accordion
                      className="main-section-accordion"
                      title="Meldinger fra kjøttkontrollen for virksomheten"
                      onClick={toggleCard('meldinger')}
                      open={openCards.includes('meldinger')}
                      paddingChildren={[1, 0, 1, 0]}
                    >
                      <ProdusentMeldingsHistorikk
                        tilsynsobjektId={tilsynsobjektId}
                      />
                    </Accordion>
                  </ShadowBox>
                )}
              </section>

              <aside aria-label="Flere tilsynsobjekt detaljer">
                {(!visibleCards ||
                  visibleCards.includes('produksjonsformer')) && (
                  <ShadowBox>
                    <Accordion
                      title="Produksjonsformer"
                      onClick={toggleCard('produksjonsformer')}
                      open={openCards.includes('produksjonsformer')}
                    >
                      <Godkjenninger
                        godkjenninger={tilsynsobjekt.godkjenninger}
                        showExpiredGodkjenninger={openCards.includes(
                          'expiredGodkjenninger'
                        )}
                        onClickExpiredGodkjenninger={toggleCard(
                          'expiredGodkjenninger'
                        )}
                      />
                    </Accordion>
                  </ShadowBox>
                )}

                {(!visibleCards || visibleCards.includes('details')) && (
                  <ShadowBox>
                    <Accordion
                      title="Detaljer om tilsynsobjekt"
                      onClick={toggleCard('details')}
                      open={openCards.includes('details')}
                    >
                      <Column spacing={2}>
                        <InfoText title="Tilsynsobjektnavn">
                          {tilsynsobjekt.navn}
                        </InfoText>
                        <InfoText title="MT-enhet for tilsynsobjekt">
                          <OrgenhetName mtEnhet={tilsynsobjekt.mtEnhet} />
                        </InfoText>
                        <InfoText title="Ansvarlig saksbehandler">
                          <OrgenhetName mtEnhet={tilsynsobjekt.saksbehandler} />
                        </InfoText>
                      </Column>
                    </Accordion>
                  </ShadowBox>
                )}

                {(!visibleCards || visibleCards.includes('virksomhet')) && (
                  <ShadowBox>
                    <Accordion
                      title={'Virksomhets\xadinformasjon (fra BRREG)'}
                      onClick={toggleCard('virksomhet')}
                      open={openCards.includes('virksomhet')}
                    >
                      <Brreg orgNr={tilsynsobjekt.orgNr} />
                    </Accordion>
                  </ShadowBox>
                )}
              </aside>
            </>
          )}
        </main>
      </div>
    </div>
  )
}
