import React from 'react'
import { ITilsynsobjekt } from '../../features/tilsynsobjekter/types'
import { TabContainer } from '../tab-container'

import { useDeepMemo } from '../../common/custom-hooks'

interface ITilsynsobjektMenuProps {
  tilsynsobjekter: ITilsynsobjekt[]
  selectedTilsynsobjektId: string
  onSelectTilsynsobjekt: (tilsynsobjektId: string) => void
}

const TilsynsobjektMenu = ({
  tilsynsobjekter,
  selectedTilsynsobjektId,
  onSelectTilsynsobjekt,
}: ITilsynsobjektMenuProps) => {
  const options = useDeepMemo(
    () =>
      tilsynsobjekter.map(
        ({ id, navn, aktivitetsBeskrivelse }: ITilsynsobjekt) => ({
          value: id || '',
          display: `${navn} (${aktivitetsBeskrivelse})` || '',
        })
      ),
    [tilsynsobjekter]
  )

  if (options.length === 0) return null

  return (
    <TabContainer
      options={options}
      onChange={onSelectTilsynsobjekt}
      currentValue={selectedTilsynsobjektId}
    />
  )
}

export { TilsynsobjektMenu }
