import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, getByOrgNr } from '../store'

import { ErrorBox, LoadingSpinner } from '@mattilsynet/mt-ui'

import { VirksomhetsDetailsView } from './view'

interface IVirksomhetsDetails {
  orgNr?: string
}

const VirksomhetsDetails = ({ orgNr }: IVirksomhetsDetails) => {
  const virksomhet = useSelector(getByOrgNr(orgNr || ''))

  const dispatch = useDispatch()
  const fetchVirksomhet = useCallback(() => {
    if (orgNr) dispatch(actions.fetchId(orgNr))
  }, [orgNr, dispatch])

  useEffect(() => {
    if (!virksomhet?.data && !virksomhet?.error && !virksomhet?.loading) {
      fetchVirksomhet()
    }
  }, [orgNr, fetchVirksomhet, virksomhet])

  if (!orgNr) {
    return (
      <div>Eier er privatperson og ikke registrert i Brønnøysundregistrene</div>
    )
  }

  if (!/^\d{9}$/.test(orgNr)) {
    return (
      <div>
        <span>
          Kunne ikke hente virksomhetinformasjon fra BRREG,
          organisasjonsnummeret{' '}
        </span>
        <span>{orgNr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')} </span>
        <span>er på feil format.</span>
      </div>
    )
  }

  if (!virksomhet || virksomhet.loading) {
    return <LoadingSpinner title="Kontakter BRREG" />
  }

  if (virksomhet.error) {
    return (
      <ErrorBox
        errorText="Kunne ikke hente virksomhetinformasjon fra BRREG"
        errorAction={fetchVirksomhet}
        errorActionText="Last innhold på nytt"
      />
    )
  }

  if (!virksomhet.data) {
    return <LoadingSpinner title="Kontakter BRREG" />
  }

  return (
    <VirksomhetsDetailsView
      beliggenhetsadresse={virksomhet.data.beliggenhetsadresse}
      kontaktperson={virksomhet.data.kontaktperson || {}}
      organisasjonsform={virksomhet.data.organisasjonsform}
      organisasjonsnummer={virksomhet.data.organisasjonsnummer || '-'}
      overordnetInfo={virksomhet.data.overordnetInfo || {}}
      postadresse={virksomhet.data.postadresse}
      virksomhetNavn={virksomhet.data.virksomhetNavn || '-'}
      slettedato={virksomhet.data.slettedato}
    />
  )
}

export default VirksomhetsDetails
