import epics from './epic'
import {
  unleashTogglesReducer,
  unleashTogglesActions,
  unleashTogglesSelectors,
  unleashTogglesActionTypes,
} from './store'
import {
  useUnleashTogglesHooks,
  useUnleashTogglesWithEftaNummer,
} from './hooks'

export const unleashToggles = {
  epics,
  reducer: unleashTogglesReducer,
  actions: unleashTogglesActions,
  actionTypes: unleashTogglesActionTypes,
  selectors: unleashTogglesSelectors,
  useUnleashTogglesHooks,
  useUnleashTogglesWithEftaNummer,
}
