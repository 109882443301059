import React, { useContext } from 'react'
import {
  Column,
  Row,
  ThemeContext,
  Text,
  RoundIndicator,
} from '@mattilsynet/mt-ui'
import { IObservasjon } from '../../ducks/observasjoner/store/types'
import './styles.css'
import styled from 'styled-components'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'
import { TOIKImageThumbnailTiny } from '../image-thumbnail'

interface IObservasjonList {
  observasjonList: IObservasjon[]
  isAmFunn: boolean
  title?: string
  withObservsajonIcon?: boolean
  withBorders?: boolean
}

const StyledListElement = styled(Column)<{
  theme: IThemeColors
  withBorders: boolean
  as: string
}>`
  & {
    display: block;
  }
  &:not(:last-child) {
    padding-bottom: 1em;
  }
  &:not(:first-child) {
    border-top: ${({ theme, withBorders }) =>
      withBorders ? `solid 2px ${theme.gray5}` : 'none'};
    padding-top: 1em;
  }
  & .observasjon-actions > *:not(:first-child) {
    margin-left: 1em;
  }
`

const StyledText = styled(Text)`
  white-space: pre-wrap;
  word-break: break-word;
`

export const ObservasjonList = ({
  observasjonList,
  isAmFunn,
  title,
  withObservsajonIcon,
  withBorders,
}: IObservasjonList) => {
  const theme = useContext(ThemeContext)

  const renderObservasjon = (observasjon?: IObservasjon) => {
    return (
      <StyledListElement
        theme={theme}
        withBorders={!!withBorders}
        key={observasjon?.observasjonId}
        as="li"
      >
        <Column spacing={2}>
          <Column spacing={1}>
            <Row justify="space-between">
              {!!observasjon?.diagnose?.kode ? (
                <Text weight="bold" size="normal">
                  {`${observasjon.diagnose.kode} - ${
                    observasjon.diagnose?.beskrivelse || ''
                  }`}
                </Text>
              ) : (
                <Text weight="light" size="normal">
                  Diagnosekode mangler
                </Text>
              )}
              {withObservsajonIcon && (
                <RoundIndicator>{isAmFunn ? 'AM' : 'PM'}</RoundIndicator>
              )}
            </Row>
            <StyledText
              weight={!observasjon?.beskrivelse ? 'light' : 'regular'}
              size="normal"
            >
              {observasjon?.beskrivelse || 'Beskrivelse mangler'}
            </StyledText>
            <div className="thumbnail-list">
              {(observasjon?.bildeIds ?? []).map((id) => (
                <TOIKImageThumbnailTiny key={id} imageId={id} />
              ))}
            </div>
          </Column>
        </Column>
      </StyledListElement>
    )
  }

  return (
    <Column spacing={2}>
      {title && (
        <Text weight="bold" size="normal">
          {title}
        </Text>
      )}
      <Column as="ul" className="observasjon-list">
        {!observasjonList.length
          ? renderObservasjon()
          : observasjonList.map((observasjon) =>
              renderObservasjon(observasjon)
            )}
      </Column>
    </Column>
  )
}
