import dayjs from 'dayjs'
import React from 'react'

import { TextLink } from '@mattilsynet/mt-ui'

import './style.css'
import { ITilsynsobjekt } from '../../ducks/tilsynsobjekt/types'

const formatDate = (date) => {
  if (date) {
    return dayjs(date).format('D. MMM YYYY')
  } else {
    return ''
  }
}

interface IGodkjenningerListViewProps {
  godkjenninger: NonNullable<ITilsynsobjekt['godkjenninger']>
}

export const GodkjenningerListView = ({
  godkjenninger,
}: IGodkjenningerListViewProps) => (
  <>
    {godkjenninger.map((godkjenning) => (
      <div key={godkjenning.type} id="godkjenning">
        <div className="title">{godkjenning.description}</div>
        <div className="dates">
          {formatDate(godkjenning.fraDato)} - {formatDate(godkjenning.tilDato)}
        </div>
      </div>
    ))}
  </>
)

interface IHideShowGodkjenningerProps {
  showExpiredGodkjenninger: boolean
  onClickExpiredGodkjenninger: () => void
  expiredGodkjenninger: NonNullable<ITilsynsobjekt['godkjenninger']>
}

const HideShowGodkjenninger = ({
  showExpiredGodkjenninger,
  onClickExpiredGodkjenninger,
  expiredGodkjenninger,
}: IHideShowGodkjenningerProps) => {
  if (showExpiredGodkjenninger) {
    return (
      <>
        <TextLink onClick={onClickExpiredGodkjenninger}>
          Skjul utgåtte produksjonsformer
        </TextLink>
        <GodkjenningerListView godkjenninger={expiredGodkjenninger} />
      </>
    )
  } else {
    return (
      <>
        <TextLink onClick={onClickExpiredGodkjenninger}>
          Vis utgåtte produksjonsformer
        </TextLink>
      </>
    )
  }
}

interface IGodkjenningerViewProps {
  expiredGodkjenninger: NonNullable<ITilsynsobjekt['godkjenninger']>
  activeGodkjenninger: NonNullable<ITilsynsobjekt['godkjenninger']>
  showExpiredGodkjenninger: boolean
  onClickExpiredGodkjenninger: () => void
}

export const GodkjenningerView = ({
  expiredGodkjenninger,
  activeGodkjenninger,
  showExpiredGodkjenninger = false,
  onClickExpiredGodkjenninger,
}: IGodkjenningerViewProps) => (
  <>
    <GodkjenningerListView godkjenninger={activeGodkjenninger} />
    {expiredGodkjenninger.length > 0 && (
      <HideShowGodkjenninger
        showExpiredGodkjenninger={showExpiredGodkjenninger}
        onClickExpiredGodkjenninger={onClickExpiredGodkjenninger}
        expiredGodkjenninger={expiredGodkjenninger}
      />
    )}
  </>
)

export const GodkjenningerEmpty = () => <div>Ingen produksjonsformer</div>
