import React, { FocusEvent, RefObject } from 'react'
import { UserMenu as UserMenuTkUi, TextLink, Row } from '@mattilsynet/mt-ui'
import { IUserState } from '../../ducks/user/types'
import './style.css'
import { downloadMutations } from '../../api/query-client-persister'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { useSingleToast } from '../../common/toast'

export interface IUserMenuProps {
  isOpen: boolean
  toggleMenu: () => void
  goToLogout: () => void
  user: IUserState['authUser'] | IUserState['me']
  userAvdeling: IUserState['avdeling']
  userMenuRef: RefObject<HTMLDivElement>
  onCloseMenuOnBlur: (event: FocusEvent<HTMLDivElement>) => void
  goToSettings: () => void
  goToAbout: () => void
  calculateFotoPath: string
}

export const UserMenuView: React.FC<IUserMenuProps> = ({
  goToAbout,
  goToLogout,
  isOpen,
  onCloseMenuOnBlur,
  toggleMenu,
  user,
  userAvdeling,
  userMenuRef,
  goToSettings,
  calculateFotoPath,
}: any) => {
  return (
    <Row
      className="user-menu-container"
      onBlur={onCloseMenuOnBlur}
      ref={userMenuRef}
    >
      <UserMenuTkUi
        isOpen={isOpen}
        onClick={toggleMenu}
        name={user.name || user.username || ''}
        title={user.title || ''}
        division={userAvdeling.name || ''}
      >
        <div className="link-group">
          <TextLink href={calculateFotoPath} linkOutside>
            Gå til Fotoløsningen
          </TextLink>
          <TextLink href="https://makuba.mattilsynet.no/" linkOutside>
            Finn hjelp i MAKUBA
          </TextLink>
          <TextLink onClick={goToSettings}>Personlige innstillinger</TextLink>
          <DownloadCache />
          <TextLink onClick={goToAbout}>Om applikasjonen</TextLink>
        </div>
        <TextLink onClick={goToLogout}>Logg ut</TextLink>
      </UserMenuTkUi>
    </Row>
  )
}

const DownloadCache = () => {
  const singleToast = useSingleToast()

  const downloadImages = async () => {
    const outputs = await downloadMutations()

    if (!outputs) {
      singleToast.showToast({
        text: 'Ingen data',
        timeoutSeconds: 3,
        dismissible: true,
        type: 'WARN',
      })
      return
    }

    const { output, bilder, metadataOutput, cacheState } = outputs

    const outputBlobs: [string, Blob][] = []

    if (output.length > 0) {
      outputBlobs.push([
        'endringer.txt',
        new Blob([output], { type: 'text/plain' }),
      ])
    }
    if (metadataOutput.length > 0) {
      outputBlobs.push([
        'bilde-metadata.txt',
        new Blob([metadataOutput], { type: 'text/plain' }),
      ])
    }
    if (cacheState.length > 0) {
      outputBlobs.push([
        'original-state.json',
        new Blob([JSON.stringify(cacheState)], { type: 'application/json' }),
      ])
    }

    const zip = new JSZip()
    outputBlobs.forEach(([name, blob]) => zip.file(name, blob))

    if (bilder.length > 0) {
      const bildeFolder = zip.folder('bilder')!
      bilder.forEach(([id, file]) => {
        try {
          const ext = file.name.split('.').pop()
          bildeFolder.file(`${id}.${ext}`, file)
        } catch (e) {
          bildeFolder.file(file.name, file)
        }
      })
    }

    const zipContent = await zip.generateAsync({ type: 'blob' })
    saveAs(zipContent, `data.zip`)

    singleToast.showToast({
      text: 'Data er lastet ned',
      timeoutSeconds: 3,
      dismissible: true,
      type: 'SUCCESS',
    })
  }

  return <TextLink onClick={downloadImages}>Last ned data</TextLink>
}
