import React, { useCallback, useEffect } from 'react'
import {
  proveanalysesvarActions,
  proveanalysesvarSelectors,
} from '../../ducks/proveanalysesvar/store'
import { useDispatch } from 'react-redux'
import {
  LoadingSpinner,
  ErrorBox,
  Table,
  TableCell,
  TableRow,
  Column,
} from '@mattilsynet/mt-ui'
import dayjs from 'dayjs'
import './style.css'
import { useTypedSelector } from '../../../../common/custom-hooks'
import { sortByKey } from '../../../../common/sorting'

export const useProveanalysesvarHook = (produsentTilsynsobjektId: string) => {
  const proveanalysesvarData = useTypedSelector(
    proveanalysesvarSelectors.getById(produsentTilsynsobjektId)
  )
  const hasProveanalysesvar =
    !!proveanalysesvarData?.data?.proeveanalysesvar?.length

  const dispatch = useDispatch()
  const fetchProveanalyseSvar = useCallback(() => {
    dispatch(proveanalysesvarActions.fetchListByIds([produsentTilsynsobjektId]))
  }, [produsentTilsynsobjektId, dispatch])

  useEffect(() => {
    if (
      !proveanalysesvarData?.data &&
      !proveanalysesvarData?.loading &&
      !proveanalysesvarData?.error &&
      produsentTilsynsobjektId
    ) {
      fetchProveanalyseSvar()
    }
  }, [proveanalysesvarData, fetchProveanalyseSvar, produsentTilsynsobjektId])
  return { hasProveanalysesvar, proveanalysesvarData, fetchProveanalyseSvar }
}

interface IProveanalysesvarProps {
  produsentTilsynsobjektId: string
}

export const Proveanalysesvar = ({
  produsentTilsynsobjektId,
}: IProveanalysesvarProps) => {
  const { fetchProveanalyseSvar, hasProveanalysesvar, proveanalysesvarData } =
    useProveanalysesvarHook(produsentTilsynsobjektId)

  if (!proveanalysesvarData || proveanalysesvarData.loading)
    return <LoadingSpinner title="Laster Prøveanalysesvar.." />

  if (proveanalysesvarData.error) {
    return (
      <Column margin={[0, 2]}>
        <ErrorBox
          errorText="Kunne ikke laste inn prøveanalysesvar.."
          errorAction={fetchProveanalyseSvar}
          errorActionText="Prøv å laste innhold på nytt"
        />
      </Column>
    )
  }

  if (!hasProveanalysesvar) {
    return (
      <Column margin={[0, 2]}>
        <ErrorBox errorText="Produsenten har ingen registrerte prøveanalysesvar de siste to årene" />
      </Column>
    )
  }

  const sortedProveAnalyseSvar = sortByKey(
    'uttattDato',
    true
  )(proveanalysesvarData.data?.proeveanalysesvar || [])

  const headers = [
    { display: 'Dato' },
    { display: 'Sted' },
    { display: 'Materiale' },
    { display: 'Prøvesett' },
    { display: 'Analysetype' },
    { display: 'Svartype' },
    { display: 'Resultat' },
    { display: 'Ant. delprøver' },
  ]

  return (
    <Table className="proveanalysesvar-table" header={headers}>
      {sortedProveAnalyseSvar.map((proveanalysesvar) => (
        <TableRow key={proveanalysesvar.proeveuttakRef}>
          <TableCell>
            {proveanalysesvar.uttattDato
              ? dayjs(proveanalysesvar.uttattDato).format('DD.MM.YY')
              : ''}
          </TableCell>
          <TableCell>
            {proveanalysesvar.proevested?.beskrivelse || ''}
          </TableCell>
          <TableCell>{proveanalysesvar.materiale?.beskrivelse || ''}</TableCell>
          <TableCell>{proveanalysesvar.proevesettNavn || ''}</TableCell>
          <TableCell>
            {proveanalysesvar.analysetype?.beskrivelse || ''}
          </TableCell>
          <TableCell>{proveanalysesvar.svartype || ''}</TableCell>
          <TableCell>
            {proveanalysesvar.proeveanalyseresultat?.beskrivelse || ''}
          </TableCell>
          <TableCell>{proveanalysesvar.antallDelproever || ''}</TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
