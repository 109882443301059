import React, { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Column,
  Row,
  SearchInput,
  SelectList,
} from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../../components/modal-wrapper'
import './style.css'
import { IAktivitet } from '../../ducks/kvittering/types'
import { useDebounce } from '../../common/custom-hooks'
import { ITilsynsobjekt } from '../../features/tilsynsobjekter/types'
import { ModalButtonsContainer } from '../../components/modal-buttons-container'
import { ModalButton } from '../../components/modal-button'
import { modalContentPadding } from '../../components/modal-buttons-container/constants'
import {
  useRemoveTilsynsobjekt,
  useTilsynsobjekter,
} from '../../features/tilsynsobjekter'
import { useKvitteringId } from '../../common/kvittering-context'

interface IAktivitetModal {
  isOpen: boolean
  onCancel: () => void
  aktivitetOptions?: IAktivitet[]
  selectedAktivitet?: IAktivitet
  onSelect: (value) => void
  onSearch: (searchValue) => void
  onUnselectMidlertidigTilsynsobjekt: (tilsynsobjekt) => void
  unselectedMidlertidigTilsynsobjekt?: ITilsynsobjekt
}

export const AktivitetModal = ({
  isOpen,
  onCancel,
  aktivitetOptions,
  selectedAktivitet,
  onSelect,
  onSearch,
  onUnselectMidlertidigTilsynsobjekt,
  unselectedMidlertidigTilsynsobjekt,
}: IAktivitetModal) => {
  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, 200)

  const kvitteringId = useKvitteringId()

  const { data: tilsynsobjekter } = useTilsynsobjekter(kvitteringId)

  const { mutate: removeTilsynsobjekt } = useRemoveTilsynsobjekt(kvitteringId)

  const isAktivitetSelected = useMemo(() => {
    if (tilsynsobjekter) {
      return tilsynsobjekter.length >= 1
    }

    return false
  }, [tilsynsobjekter])

  useEffect(() => {
    onSearch(debouncedSearchValue)
  }, [onSearch, debouncedSearchValue])

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur()
      onSearch(searchValue)
    }
  }

  return (
    <ModalWrapper
      alignTop
      fullscreenMobile
      isOpen={isOpen}
      onCancel={onCancel}
      title="Velg aktivitet på det midlertidige tilsynsobjektet"
      className={'aktivitet-modal'}
    >
      <Column padding={modalContentPadding}>
        <Row spacing={1} margin={[2, 2]}>
          <SearchInput
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => onEnter(e)}
            placeholder={'Søk etter aktivitet'}
            value={searchValue || ''}
          />
          <Button onClick={() => onSearch(searchValue)}>{'Søk'}</Button>
        </Row>
        <Row spacing={1} margin={[2, 2]}>
          <p>
            Her kan du legge til relevant aktivitet på tilsynsobjektet. Det
            overføres ikke informasjon om tilsynsobjektet til Mats, og du må
            selv sørge for korrekt registrering når du er tilbake på kontoret.
          </p>
        </Row>

        {!aktivitetOptions || aktivitetOptions.length === 0 ? (
          <Column align={'center'} padding={[2, 2]}>
            <p>Ingen treff</p>
          </Column>
        ) : (
          <Column>
            <SelectList
              dataList={aktivitetOptions}
              onClick={(selectedValue) => {
                if (unselectedMidlertidigTilsynsobjekt) {
                  removeTilsynsobjekt({
                    kvitteringId,
                    tilsynsobjekt: unselectedMidlertidigTilsynsobjekt,
                  })

                  onUnselectMidlertidigTilsynsobjekt(null)
                }
                onSelect(selectedValue)
              }}
              selected={[
                selectedAktivitet?.value ? selectedAktivitet.value : '',
              ]}
            />
          </Column>
        )}
      </Column>
      <ModalButtonsContainer>
        <ModalButton secondary={!isAktivitetSelected} onClick={onCancel}>
          {isAktivitetSelected ? 'Ferdig' : 'Lukk'}
        </ModalButton>
      </ModalButtonsContainer>
    </ModalWrapper>
  )
}
