import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './style.css'
import { kodeverk } from '../../features/kodeverk'
import { kvitteringActions } from '../../ducks/kvittering/actions'
import { useDeepEffect, useTypedSelector } from '../../common/custom-hooks'
import { IStoreState } from '../../reducers/types'
import { clearSak } from '../../ducks/sak/actions'
import { ForberedNewTilsynView } from './view'
import { onKeyDown } from '../../helpers'
import {
  offlineInSakAndTilsynsobjekt,
  sakCannotBeUsed,
  useSingleToast,
} from '../../common/toast'
import { createViewContext } from '../../features/views'
import { useGoBack, useTKNavigate } from '../../common/navigation'
import { getSakData, getSaksnummer, useSak } from '../../features/sak'
import {
  useCreateKvitteringMutation,
  useKvittering,
  useUpdateKvittering,
} from '../../features/kvitteringer'
import { kvitteringSelectors } from '../../ducks/kvittering/selectors'

const [ViewsProvider, useViewsContext] = createViewContext<
  'default' | 'tidligereKontrollpunkter'
>('default')

export { useViewsContext }

const ForberedNewTilsyn: React.FC<{ match?: any }> = () => {
  const dispatch = useDispatch()
  const navigate = useTKNavigate()
  const goBack = useGoBack()
  const singleToast = useSingleToast()

  const isOffline = useTypedSelector((state: IStoreState) => state.ui.offline)

  const kodeverkAktiviteterBehandlesIMats = kodeverk.hooks.useKodeverk(
    'AKTIVITET',
    { filter: 'H' }
  )

  const [showSak, setShowSak] = useState(false)

  const currentKvitteringId = useSelector(
    kvitteringSelectors.getCurrentKvitteringId
  )
  const { mutateAsync: createKvittering } = useCreateKvitteringMutation()

  const { mutate: updateKvittering } = useUpdateKvittering()

  const { data: kvittering } = useKvittering(currentKvitteringId)

  const kvitteringSaksnummer = kvittering?.noarksakSekvensnummer
    ? `${kvittering.noarksakAar}/${kvittering.noarksakSekvensnummer}`
    : ''

  const [sakNumberValue, setSakNumberValue] = useState('')
  const [saknummer, setSaknummer] = useState(kvitteringSaksnummer)
  const [ensureToastCounter, setEnsureToastCounter] = useState(0)

  const {
    data: sakData,
    isPending: isSakLoading,
    isError: isSakError,
    error: sakError,
  } = useSak(getSaksnummer(saknummer, kvittering))

  const onStartTilsyn = (id: string) => {
    dispatch(kvitteringActions.setCurrentKvittering(kvittering))

    navigate(`/start-tilsyn/${id}`)
  }

  const onChange = (e) => {
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt())
    }
    setSakNumberValue(onKeyDown(e, sakNumberValue))
  }

  const onAddSak = () => {
    if (sakNumberValue.length > 0) {
      setShowSak(true)
      if (isOffline) {
        return singleToast.showToast(offlineInSakAndTilsynsobjekt())
      }
      setSaknummer(sakNumberValue)

      // Is used so the sakCannotBeUsed toast is shown each time the user tries to add a sak (if there is an error)
      setEnsureToastCounter((curr) => curr + 1)
    }
  }

  const onCancelSak = () => {
    setShowSak(false)
    if (isOffline) {
      return singleToast.showToast(offlineInSakAndTilsynsobjekt())
    }

    if (kvitteringRef.current) {
      updateKvittering({
        kvittering: {
          ...kvitteringRef.current,
          noarksakAar: '',
          noarksakSekvensnummer: '',
        },
      })
    }

    setSakNumberValue('')
    setSaknummer('')
  }

  const onGoBack = () => {
    dispatch(kvitteringActions.clearCurrentKvittering())
    dispatch(kvitteringActions.setCurrentKvitteringId(''))
    dispatch(clearSak())
    goBack()
  }

  const kvitteringRef = useRef(kvittering)
  useEffect(() => {
    kvitteringRef.current = kvittering
  }, [kvittering])

  useDeepEffect(() => {
    const [noarksakAar, noarksakSekvensnummer] = sakData?.sakNumber
      ? sakData.sakNumber.split('/')
      : ['', '']

    if (sakData?.sakNumber && !kvitteringRef.current) {
      createKvittering({ noarksakAar, noarksakSekvensnummer })
    }

    if (
      isSakError &&
      kvitteringRef.current &&
      kvitteringRef.current.noarksakAar !== '' &&
      kvitteringRef.current.noarksakSekvensnummer !== ''
    ) {
      updateKvittering({
        kvittering: {
          ...kvitteringRef.current,
          noarksakAar: '',
          noarksakSekvensnummer: '',
        },
      })
    }

    if (
      sakData?.sakNumber &&
      kvitteringRef.current &&
      kvitteringRef.current.noarksakSekvensnummer !== noarksakSekvensnummer
    ) {
      updateKvittering({
        kvittering: {
          ...kvitteringRef.current,
          noarksakAar,
          noarksakSekvensnummer,
        },
      })
    }

    if (isSakError) {
      singleToast.showToast(sakCannotBeUsed())
    }
  }, [
    sakData,
    isSakError,
    singleToast,
    ensureToastCounter,
    createKvittering,
    updateKvittering,
  ])

  return (
    <ViewsProvider>
      <ForberedNewTilsynView
        sakNumberValue={sakNumberValue}
        sakData={getSakData(
          sakData,
          isSakLoading,
          isSakError,
          sakError,
          saknummer
        )}
        onChange={onChange}
        onStartTilsyn={onStartTilsyn}
        onGoBack={onGoBack}
        onAddSak={onAddSak}
        onCancelSak={onCancelSak}
        showSak={showSak}
        kodeverkAktiviteterBehandlesIMats={kodeverkAktiviteterBehandlesIMats}
      />
    </ViewsProvider>
  )
}

export default ForberedNewTilsyn
