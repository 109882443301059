import React from 'react'
import { BildeThumbnail, useBilde } from '../../../bilder'
import { useKvitteringId } from '../../../../common/kvittering-context'

interface TOIKImageThumbnailProps {
  imageId: string
}

/**
 * TOIKImageThumbnailTiny is only meant for tilsynsobjektinfo-komponent.
 * @param imageId
 * @constructor
 */
export const TOIKImageThumbnailTiny = ({
  imageId,
}: TOIKImageThumbnailProps) => {
  const kvitteringId = useKvitteringId()
  const [url] = useBilde(kvitteringId, imageId, 'tiny', 'MAKKS')

  return <BildeThumbnail alt={imageId} url={url ?? ''} />
}
