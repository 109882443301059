import React from 'react'
import { MtPersonWithPigIcon } from '../../assets/mt-person-pig'
import './style.css'

export default function Fallback() {
  const makubaLink = (
    <a href="https://makuba.mattilsynet.no/" target="_blank" rel="noreferrer">
      Makuba
    </a>
  )

  return (
    <section className="error-boundary">
      <h1>Oisann! Her ble det krøll</h1>
      <p>
        {/* Våre data-inspektører har fått beskjed om at noe gikk skeis. De vil ta
          et tilsyn av koden så raskt de kan. Noen ganger kan det hjelpe å laste
          inn siden på nytt, men hvis problemet fortsetter kan du alltids opprette
          en sak i */}
        Vennligst rapporter feilen til oss i {makubaLink}, så vi kan se nærmere
        på den. <br />
        Noen ganger kan det hjelpe å oppdatere siden, så prøv gjerne det også.
      </p>
      <div className="error-boundary__illustration">
        <MtPersonWithPigIcon />
      </div>
      <button
        className="error-boundary__reload"
        onClick={() => window.location.reload()}
      >
        Last inn siden på nytt
      </button>
    </section>
  )
}
