import { Checkmark, ThemeContext, Row, Cross } from '@mattilsynet/mt-ui'
import React, { useContext } from 'react'
import './styles.css'

interface IStatusProps {
  success: boolean
  error: boolean
  isLoading: boolean
}

export const Status = ({ success, error, isLoading }: IStatusProps) => {
  const theme = useContext(ThemeContext)
  const renderSavingStatus = () => {
    if (isLoading) {
      return 'Lagrer...'
    }

    // TODO: Create X icon in mt-ui (line 32)
    if (error) {
      return (
        <>
          <Cross color="white" innerColor="red" />
          Siste endring ble ikke lagret
        </>
      )
    }

    if (success) {
      return (
        <>
          <Checkmark color={theme.signalGreen} />
          Lagret
        </>
      )
    }

    return undefined
  }
  return (
    <Row
      justify="center"
      id="bottom-status"
      backgroundColor={theme.white}
      padding={1}
      align="center"
    >
      {renderSavingStatus()}
    </Row>
  )
}
