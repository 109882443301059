import React, { useContext } from 'react'
import { useTilsynsobjektHooks } from '../../ducks/tilsynsobjekt'
import { useObservasjonHooks } from '../../ducks/observasjoner'
import {
  ShadowBox,
  Column,
  Row,
  TimeIcon,
  ActionIcon,
  IconButton,
  ThemeContext,
  ErrorBox,
  LoadingDots,
  Text,
  LetterCheckIcon,
} from '@mattilsynet/mt-ui'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { ObservasjonList } from '../observasjon-list'
import { getProdusentNummerFromProdusent } from '../../ducks/tilsynsobjekt/helpers'
import styled from 'styled-components'
import { FunnIndividList } from '../funn-individ-list'
import { IThemeColors } from '@mattilsynet/mt-ui/dist/assets/colors'
import { IFunn } from '../../hooks/funn-hooks'
import { AKTIVITET_SVINEHOLD } from '../../constants'

interface IOwnProps {
  funn: IFunn
  onClickActionButton?: (funn: IFunn) => void
  withProdusent?: boolean
  withLastEditedBy?: boolean
  tilknyttedeFunn?: IFunn[]
}

const StyledHeader = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 0.25em;
    height: 1.25em;
  }
`

const StyledTag = styled.div<{ className?: string; theme: IThemeColors }>`
  & {
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.gray5};
    min-width: 4em;
    padding: 0.3125em 1em;
    border-radius: 10em;
  }

  &.paabegynt {
    border: 3px solid ${({ theme }) => theme.statusSuccessGreen};
    background-color: ${({ theme }) => theme.white};
  }
  &.ferdig {
    background-color: ${({ theme }) => theme.statusSuccessGreen};
  }
  &.avsluttet {
    background-color: ${({ theme }) => theme.gray5};
  }
  &.bruktIArkivertMelding {
    background-color: ${({ theme }) => theme.gray4};
  }
`

const StatusTag = ({
  theme,
  status,
  bruktIArkivertMelding,
}: {
  status?: IFunn['status']
  bruktIArkivertMelding?: IFunn['bruktIArkivertMelding']
  theme: IThemeColors
}) => {
  const className = bruktIArkivertMelding
    ? classNames({
        bruktIArkivertMelding,
      })
    : classNames({
        paabegynt: status === 'KLADD' || status === 'FULLVERDIG',
        ferdig: status === 'FERDIG',
        avsluttet: status === 'AVSLUTTET',
      })

  const renderContent = () => {
    if (status === 'KLADD' || status === 'FULLVERDIG') {
      return <Text size="small">PÅBEGYNT</Text>
    } else if (status === 'FERDIG') {
      return <Text size="small">FERDIG</Text>
    } else if (status === 'AVSLUTTET') {
      return <Text size="small">AVSLUTTET</Text>
    } else if (bruktIArkivertMelding) {
      return (
        <Row spacing={0.5} align="center">
          <LetterCheckIcon size="small" color={theme.gray1} />
          <Text uppercase size="small">
            Brukt i melding
          </Text>
        </Row>
      )
    }
    return <Text size="small">{status}</Text>
  }

  return (
    <StyledTag className={className} theme={theme}>
      {renderContent()}
    </StyledTag>
  )
}

export const FunnCard = ({
  funn,
  onClickActionButton,
  withLastEditedBy,
  withProdusent,
  tilknyttedeFunn,
}: IOwnProps) => {
  const { observasjonList, observasjonLoadingStatus, fetchObservasjonList } =
    useObservasjonHooks(funn.funnId, funn.isAmFunn)
  const { tilsynsobjekt, tilsynsobjektLoadingStatus } = useTilsynsobjektHooks(
    funn?.produsentTilsynsobjektId
  )

  const theme = useContext(ThemeContext)

  const renderProdusent = () => {
    if (!funn.produsentTilsynsobjektId)
      return (
        <Text size="normal" weight="light">
          PRODUSENT MANGLER
        </Text>
      )
    if (tilsynsobjektLoadingStatus?.loading) return <LoadingDots />
    if (!tilsynsobjekt)
      return (
        <Text size="normal" weight="light">
          Kunne ikke hente produsentnavn
        </Text>
      )
    return (
      <Text size="normal" weight="bold" uppercase>
        {`${tilsynsobjekt.virksomhetsNavn} - ${getProdusentNummerFromProdusent(
          tilsynsobjekt
        )} (${tilsynsobjekt.aktivitet?.beskrivelse})`}
      </Text>
    )
  }

  const renderIndividInfo = () => {
    const individTitle =
      tilsynsobjekt?.aktivitet?.id === AKTIVITET_SVINEHOLD
        ? funn.isAmFunn
          ? 'Antall dyr'
          : 'Skrottnummer'
        : funn.isAmFunn
          ? 'Individer'
          : 'Individnummer/skrottnummer'

    return (
      <Column spacing={1}>
        <b>{individTitle}</b>
        <FunnIndividList
          funn={funn}
          isAktivitetSvinehold={
            tilsynsobjekt?.aktivitet?.id === AKTIVITET_SVINEHOLD
          }
        />
      </Column>
    )
  }

  const renderObservasjonList = () => {
    if (observasjonLoadingStatus?.loading) {
      return <LoadingDots />
    }

    if (observasjonLoadingStatus?.error) {
      return (
        <ErrorBox
          errorText="Kunne ikke laste inn observasjoner for funn"
          errorAction={() => {
            return fetchObservasjonList()
          }}
          errorActionText="Prøv igjen"
        />
      )
    }

    return (
      <ObservasjonList
        withObservsajonIcon
        observasjonList={observasjonList || []}
        isAmFunn={funn.isAmFunn}
        title="Observasjon(er)"
      />
    )
  }

  const lastEditText = `Sist redigert av ${funn.oppdatertAv} ${dayjs(
    funn.oppdatert
  ).format('D.MMM [kl.] HH.mm')}`

  return (
    <>
      <Column
        spacing={2}
        padding={2}
        className="funn-card-content"
        id={funn.funnId}
      >
        {withProdusent && renderProdusent()}
        <Row justify="space-between">
          <StyledHeader>
            <TimeIcon color={theme.gray1} />
            {dayjs(funn.opprettet).format('HH.mm')}
          </StyledHeader>
          {onClickActionButton && (
            <Row margin={-2}>
              <IconButton
                ariaLabel="Funn handlinger"
                large
                icon={<ActionIcon />}
                onClick={() => onClickActionButton(funn)}
              />
            </Row>
          )}
        </Row>
        <Row spacing={1}>
          <StatusTag status={funn.status} theme={theme} />
          {funn.bruktIArkivertMelding && (
            <StatusTag
              bruktIArkivertMelding={funn.bruktIArkivertMelding}
              theme={theme}
            />
          )}
        </Row>
        <Column spacing={1}>
          <Text weight="bold">Kontrolldato</Text>
          <Text>{dayjs(funn.kontrollDato).format('DD. MMM YYYY')}</Text>
        </Column>
        {renderIndividInfo()}
        {renderObservasjonList()}
        {withLastEditedBy && (
          <Text weight="light" size="medium">
            {lastEditText}
          </Text>
        )}
      </Column>
      {!!tilknyttedeFunn?.length &&
        tilknyttedeFunn?.map((funn) => (
          <FunnCard
            key={funn.funnId}
            funn={funn}
            onClickActionButton={onClickActionButton}
            withLastEditedBy
          />
        ))}
    </>
  )
}

export const FunnCardItem: React.FC<IOwnProps> = (props) => (
  <ShadowBox>
    <FunnCard {...props} />
  </ShadowBox>
)
